import React from "react";
import SimpleLayout from "@src/layouts/SimpleLayout";
import PageLayout from "@src/layouts/PageLayout";
import BlogLayout from "@src/layouts/BlogLayout";
import MobileLayout from "@src/layouts/mobile/MobileLayout";
import DevBlogLayout from "@src/layouts/DevBlogLayout";
import IdeasLayout from "@src/layouts/IdeasLayout";
import { initLiteLogClient } from "@src/components/mobile/MobileDataContext";
import { initLogClient } from "@src/components/AppDataContext";

const LayoutChooser = ({ children, pageContext }) => {
  if (pageContext.layout === "mobile") {
    initLiteLogClient();
  } else {
    initLogClient();
  }

  if (pageContext.layout === "simple") {
    return <SimpleLayout pageContext={pageContext}>{children}</SimpleLayout>;
  } else if (pageContext.layout === "blog") {
    return <BlogLayout pageContext={pageContext}>{children}</BlogLayout>;
  } else if (pageContext.layout === "mobile") {
    return <MobileLayout pageContext={pageContext}>{children}</MobileLayout>;
  } else if (pageContext.layout === "dev-blog") {
    return <DevBlogLayout pageContext={pageContext}>{children}</DevBlogLayout>;
  } else if (pageContext.layout === "ideas") {
    return <IdeasLayout pageContext={pageContext}>{children}</IdeasLayout>;
  }

  return <PageLayout pageContext={pageContext}>{children}</PageLayout>;
};

export default LayoutChooser;
